<template>
    <cluji-card title="Réservation du local">
        <b-row>
            <b-col class="datetimepicker-container" xl="8" offset-xl="2">
                <two-dates-picker @dateDebutChange="updateDateDebut" @dateFinChange="updateDateFin"/>
                <label class="mt-3"
                       v-b-tooltip.hover="'Détaillez l\'utilisation que vous comptez faire du local'">
                    Commentaire &middot;
                    <small>
                        <font-awesome-icon icon="question-circle"/>
                    </small>
                </label>
                <balloon-editor v-model="form.commentaireUtilisateur"/>
                <br/>
                <b-checkbox v-if="this.covidRulesWikiId" v-model="form.covidRulesAccepted">
                    Je m'engage à faire respecter les 
                    <router-link :to="covidRulesLink" target='_blank'>
                        consignes COVID de l'association
                    </router-link>
                    au sein du local pendant ma réservation. En cas de non-respect de ces 
                    consignes, le bureau se réserve le droit de me refuser l'emprunt du 
                    local en période COVID.
                </b-checkbox>
            </b-col>
        </b-row>
        <div class="text-center">
            <b-button variant="success" class="mt-3" @click="submit"
                      :disabled="!form.covidRulesAccepted && covidRulesWikiId">
                <font-awesome-icon icon="check"/>
                Valider
            </b-button>
        </div>
    </cluji-card>
</template>

<script>
    const TwoDatesPicker = () => import('@/components/TwoDatesPicker');
    const ClujiCard      = () => import('@/components/ClujiCard');
    const BalloonEditor  = () => import('@/components/BalloonEditor');

    import {apiPath}             from '@/util/http';
    import alert                 from '@/util/alert';
    import {checkNonEmptyFields} from '@/util/form';
    import {updateBadgesBureau}  from '@/util/badge';
    import {linkToArticlePage}   from '@/util/wiki';

    export default {
        name: "NouvelleReservation",
        components: {
            TwoDatesPicker, ClujiCard, BalloonEditor
        },
        data: () => ({
            loading: false,
            form: {
                dateDebut: null,
                dateFin: null,
                commentaireUtilisateur: null,
                covidRulesAccepted: false
            }
        }),
        computed: {
            covidRulesWikiId() {
                return process.env.VUE_APP_COVID_RULES_WIKI_ID;
            },
            covidRulesLink() {
                return {
                    name: 'wiki_article',
                    params: {
                        articleId: this.covidRulesWikiId,
                        articleName: "consignes-COVID"
                    }
                };
            }
        },
        methods: {
            updateDateDebut(val) {
                this.form.dateDebut = val;
            },
            updateDateFin(val) {
                this.form.dateFin = val;
            },
            submit() {
                if (!checkNonEmptyFields(this.form, ['dateDebut', 'dateFin', 'commentaireUtilisateur'])) {
                    this.$toaster.error('Tous les champs sont obligatoires');
                    return;
                }

                alert.loading();

                this.axios.post(apiPath('new_reservation'), this.form)
                    .then(response => {
                        if (response.data.error && response.data.error === "covid-rules") {
                            this.$toaster.error("Vous devez vous engager à faire respecter au sein du local les consignes COVID de l'association pour réserver le local");
                        } else {
                            this.$toaster.success('Réservation enregistrée avec succès, vous recevrez un mail quand le bureau aura examiné votre demande (pensez à vérifier vos spams)');
                            updateBadgesBureau();
                            this.$router.push({name: 'dashboard_reservations'});
                        }
                    })
                    .catch(() => this.$toaster.error("Impossible d'enregistrer votre réservation"))
                    .finally(alert.stopLoading);
            }
        }
    }
</script>
